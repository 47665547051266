<template>
  <v-row>
    <v-col cols="12">
      <consolidate-einvoice-card> </consolidate-einvoice-card>
    </v-col>
  </v-row>
</template>
  <script>
import ConsolidateEinvoiceCard from "@/components/einvoice/ConsolidateEinvoiceCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Consolidate E-Invoice",
  },

  components: {
    ConsolidateEinvoiceCard,
  },

  data() {
    return {};
  },
};
</script>