<template>
  <div>
    <base-card :loading="cardLoading">
      <div>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="6">Consolidate E-Invoice</v-col>
            <v-col cols="12" md="6" lg="6">
              <v-select
                class="mt-n1 mb-n10"
                label="Select Branch"
                v-model="selectBranch"
                :items="branchList"
                item-text="name"
                item-value="branch_url"
                dense
                solo
              ></v-select
            ></v-col>
          </v-row>
        </v-card-title>
      </div>
    </base-card>

    <v-row>
      <v-col cols="12">
        <v-card color="transparent">
          <iframe
            v-if="selectBranch"
            :src="iframeUrl"
            width="100%"
            height="700"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";

export default {
  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      companyID: "",
      cardLoading: true,
      selectBranch: "",
      branchList: [],
      iframeUrl: "https://c-einvoice.optimy.com.my/?branch_url=",
      iframeHeight: "500px", 
    };
  },
  watch: {
    selectBranch() {
      this.iframeUrl = this.iframeUrl + this.selectBranch;
    },
  },

  mounted() {
    this.updateIframeHeight();
    window.addEventListener("resize", this.updateIframeHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIframeHeight);
  },

  computed: {},

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
  },

  methods: {
    updateIframeHeight() {
      if (window.innerWidth < 600) {
        // Adjust height for mobile screens
        this.iframeHeight = "300px";
      } else {
        this.iframeHeight = "500px"; // Default height for desktop
      }
    },
  
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranchWithEinvoice", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  <style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
  